import {Loading} from '@shopify/app-bridge-react';
import {Card, Page} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {
  PageFooterHelp,
  SkeletonIndexFilters,
  SkeletonTable,
} from '~/components';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {productRecommendationsURL} from '~/utils/urls';

import translations from './translations';

export function ProductRecommendationsListSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));
  return (
    <>
      <Loading />
      <Page
        fullWidth
        title={i18n.translate('ProductRecommendationsList.title')}
        primaryAction={{
          content: i18n.translate(
            'ProductRecommendationsList.actions.createGroup',
          ),
          url: productRecommendationsURL('new'),
        }}
      >
        <Card padding="0">
          <SkeletonIndexFilters />
          <SkeletonTable numColumns={4} withThumbnail />
        </Card>

        <PageFooterHelp resourceName="recos" />
      </Page>
    </>
  );
}
